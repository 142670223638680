import React from "react";
import "../App.css";
import AddNewVideo from "../components/AddNewVideo";

export default function NewVideo() {
  return (
    <div className="App">
      <header className="App-header">
        <AddNewVideo />
      </header>
    </div>
  );
}
